import React, { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";

const TimeComponent = () => {
    const [currentTime, setCurrentTime] = useState(moment());

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(moment());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <Time>
            {currentTime.format("D MMMM, HH:mm")}
        </Time>
    );
};

export default TimeComponent;

const Time = styled.p`
    font-size: 13px;
    text-wrap: nowrap;
    color: #717171;
`