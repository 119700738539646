import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDevice, setOrientation } from '../redux/reducers/utils/commonReducer';

const useDeviceInfo = () => {
  const dispatch = useDispatch();

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    if (/android/i.test(userAgent)) {
      return true;
    }
  
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }
  
    return false;
  };
  
  const getDeviceOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      return 'portrait';
    }
    if (window.matchMedia("(orientation: landscape)").matches) {
      return 'landscape';
    }
    return 'unknown';
  };

  const updateDeviceInfo = () => {
    const isMobile = isMobileDevice();
    const orientation = getDeviceOrientation();
    dispatch(setDevice(isMobile ? 'mobile' : 'desctop'));
    dispatch(setOrientation(orientation));
  };

  useEffect(() => {
    updateDeviceInfo();

    window.addEventListener('resize', updateDeviceInfo);
    window.addEventListener('orientationchange', updateDeviceInfo);

    return () => {
      window.removeEventListener('resize', updateDeviceInfo);
      window.removeEventListener('orientationchange', updateDeviceInfo);
    };
  }, [dispatch]);
};

export default useDeviceInfo;
