import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { CheckIcon, LogoIcon, SpinnerIcon } from '../../components/Icons';
import Button from '../../components/Button';
import { useSelector } from 'react-redux';
import { Flex } from '../../styles';

const Loader = () => {
    const { isPointsLoadStatus } = useSelector((store) => store.map);
    const [visible, setVisible] = useState(true);
    const [isStepOneLoad, setIsStepOneLoad] = useState(false);
    const [isStepTwoLoad, setIsStepTwoLoad] = useState(false);
    const [isStepThreeLoad, setIsStepThreeLoad] = useState(false);

    useEffect(() => {
        if (!isStepOneLoad) {
            const timer = setTimeout(() => {
                setIsStepOneLoad(true);
            }, 3000);
            return () => clearTimeout(timer);
        }
        if (isStepOneLoad && !isStepTwoLoad) {
            const timer = setTimeout(() => {
                setIsStepTwoLoad(true);
            }, 3000);
            return () => clearTimeout(timer);
        }
        if (isStepOneLoad && isStepTwoLoad && isPointsLoadStatus === 'fulfilled' ) {
            setIsStepThreeLoad(true);
        }
    }, [
        isStepOneLoad,
        isStepTwoLoad,
        isPointsLoadStatus,
    ]);

    const handleButtonClick = () => {
        setVisible(false);
    }

    return (
        <Wrapper visible={visible}>
            <Content>
                <Flex justify='center' wrap='wrap' gap='60px'>
                    <Flex align='center' column width='300px'>
                        <StyledLogo/>
                        <Text>Доставка обедов из столовых, кафе и ресторанов. Бесплатно.</Text>
                    </Flex>
                    <Spinners>
                        <Spinner visible={true}>
                            {!isStepOneLoad
                                ? <><SpinnerIcon/><p style={{color: '#FFFFFF80'}}>Считаем города, курьеров и маршруты...</p></>
                                : <><CheckIcon/><p>Города, курьеры и маршруты посчитаны</p></>
                            }
                        </Spinner>
                        <Spinner visible={isStepOneLoad}>
                            {!isStepTwoLoad
                                ? <><SpinnerIcon/><p style={{color: '#FFFFFF80'}}>Загружаем заказы за последние сутки...</p></>
                                : <><CheckIcon/><p>Заказы загружены</p></>
                            }
                        </Spinner>
                        <Spinner visible={isStepTwoLoad}>
                            {!isStepThreeLoad
                                ? <><SpinnerIcon/><p style={{color: '#FFFFFF80'}}>Размещаем заказы на карте...</p></>
                                : <><CheckIcon/><p>Заказы размещены</p></>
                            }
                        </Spinner>
                    </Spinners>
                </Flex>
                <StyledButton disabled={!isStepThreeLoad} danger onClick={handleButtonClick}>Посмотреть заказы</StyledButton>
            </Content>
        </Wrapper>
    );
};

export default Loader;

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #292929;
    transition: all .5s;
    height: ${(props) => props.visible ? '100%' : '0'};
    visibility: ${(props) => props.visible ? 'visible' : 'hidden'};
    opacity: ${(props) => props.visible ? '1' : '0'};
    overflow: hidden;
`
const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 700px;
`
const StyledLogo = styled(LogoIcon)`
    margin-bottom: 20px;
    width: 180px;
    font-weight: 600;
    path {
        fill: url(#logo-gradient);
    }
`
const Text = styled.p`
    max-width: 300px;
    line-height: 1.3;
    text-align: center;
    color: #FFFFFF80;
`
const Spinners = styled.div`
    width: 250px;
    height: 150px;
    display: flex;
    flex-direction: column;
    p {
        max-width: 200px;
    }
`
const Spinner = styled.div`
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--text-color);
    visibility: ${(props) => props.visible ? 'visible' : 'hidden'};
    opacity: ${(props) => props.visible ? '1' : '0'};
`
const StyledButton = styled(Button)`
    width: 100%;
    max-width: 300px;
    padding: 20px 0;
`