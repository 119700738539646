import React from 'react';
import styled from 'styled-components';

const Button = ({className, style, danger, disabled = false, children, onClick}) => {
    return <StyledButton
        className={className}
        style={style}
        danger={danger}
        disabled={disabled}
        onClick={onClick}
    >
        {children}
    </StyledButton>
}

export default Button;

const StyledButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    min-width: 55px;
    padding: 12px;
    background: ${(props) => props.disabled ? '#3D3D3D' : props.danger ? 'var(--main-color)' : '#272727'};
    border-radius: 16px;
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.disabled ? '#FFFFFF80' : '#ffffff'};
    text-wrap: nowrap;
    transition: all .3s;
    cursor: ${(props) => props.disabled ? 'default' : 'pointer'};
    pointer-events: all;
    &:hover {
        transform: ${(props) => (!props.disabled ? 'scale(1.05)' : 'none')};
    }
    &:active {
        transform: ${(props) => (!props.disabled ? 'scale(0.95)' : 'none')};
    }
`;
