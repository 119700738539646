import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    locale: 'ru',
    device: 'desctop',
    orientation: 'portrait',
    isSoundOn: false,
    apiUrl: process.env.REACT_APP_API_URL,
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setDevice: (state, action) => {
            state.device = action.payload;
        },
        setOrientation: (state, action) => {
            state.orientation = action.payload;
        },
        setIsSoundOn: (state, action) => {
            state.isSoundOn = action.payload;
        },
    },
});

export const {
    setDevice,
    setOrientation,
    setIsSoundOn,
} = commonSlice.actions;

export default commonSlice.reducer;
