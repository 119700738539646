import React, { useState, useEffect, useRef } from 'react';
// import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    getPoints,
    updateNewPoints,
    animatePoint,
    removeOldPoints,
    moveNewPointsToPoints,
    clearAnimatedPoints,
    setCurrentCity,
    setMapMode,
} from './redux/reducers/utils/mapReducer';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/ru'; 
import Loader from './containers/loader/Loader';
import MapContainer from './containers/map/MapContainer';
import newOrderSound from './audio/newOrder.mp3';
import Header from './containers/header/Header';
import Footer from './containers/footer/Footer';
import Blackout from './components/Blackout';
import useDeviceInfo from './hooks/useDeviceInfo';

const App = () => {
    useDeviceInfo();
    // const location = useLocation();
    const { locale, device, orientation, isSoundOn } = useSelector((store) => store.common);
    const { cities, isPointsLoadStatus, points, newPoints, currentCityId, mapMode, prevCount, newCount, deletedCount } = useSelector((store) => store.map);
    const dispatch = useDispatch();
    const [prevNewPoints, setPrevNewPoints] = useState(newPoints);
    const audioRef = useRef(new Audio(newOrderSound));
    const ws = useRef(null);
    const MAX_RECONNECT_ATTEMPTS = 10;
    let reconnectAttempts = 0;
    const mon = new URLSearchParams(window.location.search).get('mon') === 'true' || false;
    const dev = new URLSearchParams(window.location.search).get('dev') === 'true' || false;

    moment.locale(locale);

    // const cityName = decodeURIComponent(location.pathname.split("/")[1]);

    useEffect(() => {
        dispatch(getPoints())
    }, [dispatch]);

    // useEffect(() => {
    //     if (cities.length > 0) {
    //         const matchCity = cities.find(item => item.name.toLowerCase() === cityName.toLowerCase());
    //         if (matchCity) {
    //             dispatch(setMapMode('city'));
    //             dispatch(setCurrentCity(matchCity.id));
    //         }
    //     }
    // }, [cities]);

    useEffect(() => {
        const interval = setInterval(() => {
            console.log('Данные обновлены...');
            dispatch(removeOldPoints());
            dispatch(moveNewPointsToPoints());
            dispatch(clearAnimatedPoints());
        }, 60000); // 60000 = 1 минута

        return () => clearInterval(interval);
    }, [dispatch]);

    useEffect(() => {
        const connectWebSocket = () => {
            ws.current = new WebSocket(dev ? 'wss://dev.menu4me.ru/geopoints/' : process.env.REACT_APP_WSS_URL);
    
            ws.current.onopen = () => {
                console.log("WebSocket соединение установлено!");
                reconnectAttempts = 0;
            };
    
            ws.current.onmessage = (event) => {
                try {
                    const newPoint = JSON.parse(event.data);
                    if (newPoint.id) {
                        console.log('Получен новый заказ: ', newPoint.id, moment.unix(newPoint.created).format('DD.MM.YYYY HH:mm'));
                        dispatch(updateNewPoints(newPoint));
                        dispatch(animatePoint(newPoint.id));
                    }
                } catch (error) {
                    console.error("Ошибка при обработке WebSocket сообщения:", error);
                }
            };
    
            ws.current.onerror = (error) => {
                console.error("WebSocket ошибка: ", error);
            };
    
            ws.current.onclose = () => {
                console.log("WebSocket соединение закрыто!");
    
                if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
                    const reconnectDelay = Math.min(1000 * (2 ** reconnectAttempts), 30000); // Экспоненциальная задержка (до 30 сек)
                    console.log(`Попытка переподключения через ${reconnectDelay / 1000} секунд...`);
    
                    reconnectAttempts += 1;
                    setTimeout(() => connectWebSocket(), reconnectDelay);
                } else {
                    console.error("Максимальное количество попыток переподключения достигнуто.");
                }
            };
        };

        connectWebSocket(); 

        return () => {
            if (ws.current) {
                ws.current.close();
            }
        };
    }, [dispatch]);

    useEffect(() => {
        if (isSoundOn) {
            const newOrdersCount = newPoints.length - prevNewPoints.length;

            for (let i = 0; i < newOrdersCount; i++) {
                audioRef.current.cloneNode().play().catch(error => {
                    console.error("Ошибка при воспроизведении звука: ", error);
                });
            }
        }
        setPrevNewPoints(newPoints);
    }, [newPoints, isSoundOn, prevNewPoints]);

    if (device === 'mobile' && orientation === 'portrait') {
        const setVh = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        
        window.addEventListener('resize', setVh);
        setVh();
    }

    return (
        <Wrapper>
            <Loader/>
            <Header/>
            <Blackout/>
            <MapContainer/>
            <Footer/>
            {mon &&
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 100000,
                    padding: '15px',
                    background: '#333333',
                    color: '#ffffff'
                }}>
                    <div>Режим карты: {mapMode}</div>
                    <div>Текущий город: {currentCityId || 'Не выбран'}</div>
                    <div>Новая точка: {newPoints.length && `ID: ${newPoints[newPoints.length - 1]?.id}, город: ${newPoints[newPoints.length - 1]?.cityId}`}</div>
                    Кол-во точек<br/>до обновления:
                    <div>Старых точек: {points.length}</div>
                    <div>Новых точек: {newPoints.length}</div>
                    <div>Устройство: {device}</div>
                    <div>Ориентация: {orientation}</div>
                    <br/>
                    <br/>
                    Тесты:
                    <div>Начальное кол-во точек: {prevCount}</div>
                    <div>Добавлено: {newCount}</div>
                    <div>Удалено: {deletedCount}</div>
                    <div>Ожидаемое количество: {prevCount - deletedCount + newCount}</div>
                </div>
            }
        </Wrapper>
    );
}

export default App;

const Wrapper = styled.div`
    position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	height: calc(var(--vh, 1vh) * 100);
	overflow: hidden;
`;
