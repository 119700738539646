import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';
const apiUrl = process.env.REACT_APP_API_URL;

const initialState = {
    globalMapCenter: [53.511311, 49.418084],
    mapMode: 'global', //'city'
    currentCityId: null,
    isPointsLoadStatus: null,
    stats: [],
    cities: [],
    points: [],
    newPoints: [],
    animatedPoints: {},

    // для тестов
    prevCount: 0,
    deletedCount: 0,
    newCount: 0,
};

const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setMapMode: (state, action) => {
            state.mapMode = action.payload;
        },
        setCurrentCity: (state, action) => {
            state.currentCityId = action.payload;
        },
        removeOldPoints: (state) => {
            const currentTime = Math.floor(Date.now() / 1000);
            const thresholdTime = 24 * 60 * 60;

            const filteredPoints = state.points.filter(point => {
                if (!((currentTime - point.created) < thresholdTime)) {
                    console.log('Удалена точка созданная: ', moment.unix(point.created).format('DD.MM.YYYY HH:mm'));
                }
                return (currentTime - point.created) < thresholdTime;
            });
            const deletedPointsCount = state.points.length - filteredPoints.length;

            // Обновляем состояние
            state.points = filteredPoints;
            state.deletedCount += deletedPointsCount;
        },
        updateNewPoints: (state, action) => {
            const newPoint = action.payload;

            if (state.currentCityId === null || state.currentCityId === newPoint.cityId) {
                state.newPoints = [...state.newPoints, newPoint];
            }
        },
        moveNewPointsToPoints: (state) => {
            state.points.push(...state.newPoints);
            state.newCount += state.newPoints.length
            state.newPoints = [];
        },
        updateAnimatedPoints: (state, action) => {
            const { pointId, isAnimating } = action.payload;
            return {
                ...state,
                animatedPoints: {
                    ...state.animatedPoints,
                    [pointId]: isAnimating
                }
            };
        },
        clearAnimatedPoints: (state) => {
            state.animatedPoints = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPoints.pending, (state) => {
                state.isPointsLoadStatus = 'pending';
            })
            .addCase(getPoints.fulfilled, (state, action) => {
                state.isPointsLoadStatus = 'fulfilled';
                state.cities = action.payload.cities;
                state.points = action.payload.points;
                state.stats = action.payload.stats;

                state.prevCount = action.payload.points.length;
            })
            .addCase(getPoints.rejected, (state) => {
                state.isPointsLoadStatus = 'rejected';
            });
    },
});

export const {
    setMapMode,
    setCurrentCity,
    removeOldPoints,
    updateNewPoints,
    moveNewPointsToPoints,
    updateAnimatedPoints,
    clearAnimatedPoints,
} = mapSlice.actions;

export default mapSlice.reducer;

export const getPoints = createAsyncThunk('map/getPoints', async (_, {rejectWithValue }) => {
    const data = {
        service: 'SalesReport',
        method: 'GetOrders',
    }
    
    try {
        const response = await axios.post(apiUrl, JSON.stringify(data));
        const cities = response.data.data.cities;
        const points = response.data.data.points;
        const stats = response.data.data.stats;
        
        return {
            cities,
            points,
            stats,
        };
    } catch (error) {
        return rejectWithValue('Ошибка подключения');
    }
});

export const animatePoint = createAsyncThunk(
    'map/animatePoint',
    async (pointId, { dispatch }) => {
        dispatch(updateAnimatedPoints({ pointId, isAnimating: true }));

        await new Promise((resolve) => setTimeout(resolve, 200));

        dispatch(updateAnimatedPoints({ pointId, isAnimating: false }));
    }
);