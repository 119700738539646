import React, { useState, useEffect, useRef, useMemo } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentCity, setMapMode, removeOldPoints, moveNewPointsToPoints, clearAnimatedPoints } from '../../redux/reducers/utils/mapReducer';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { Flex } from '../../styles';
import Spinner from '../../components/Spinner';

const MapContainer = () => {
    // const navigate = useNavigate();
    const { device } = useSelector((store) => store.common);
    const { globalMapCenter, mapMode, cities, points, newPoints, animatedPoints, currentCityId } = useSelector((store) => store.map);
    const dispatch = useDispatch();
    const apiKey = process.env.REACT_APP_YANDEX_MAPS_API_KEY;
    let mapInstance = null;
    const mapRef = useRef(null);
    const maxZoom = 12;
    const [activeBaloonIndex, setActiveBaloonIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const timeoutRef = useRef(null);
    const isMobile = device === 'mobile';

    const filteredPoints = useMemo(() => {
        if (currentCityId === null) {
            const pointsByCity = points.reduce((acc, point) => {
                if (!acc[point.cityId]) {
                    acc[point.cityId] = [];
                }
                acc[point.cityId].push(point);
                return acc;
            }, {});
    
            return Object.values(pointsByCity).flatMap(cityPoints => cityPoints.slice(0, 30));
        }
    
        return points.filter(point => point.cityId === currentCityId);
    }, [points, currentCityId]);

    useEffect(() => {
        if (cities.length > 0) {
            const interval = setInterval(() => {
                setActiveBaloonIndex((prevIndex) => (prevIndex + 1) % cities.length);
            }, 4000);

            return () => clearInterval(interval);
        }
    }, [cities]);

    useEffect(() => {
        if (mapInstance && filteredPoints.length > 0) {
            const coordinates = filteredPoints.map((point) => point.coords);
    
            const minLongitude = Math.min(...coordinates.map(coord => coord[0]));
            const maxLongitude = Math.max(...coordinates.map(coord => coord[0]));
            const minLatitude = Math.min(...coordinates.map(coord => coord[1]));
            const maxLatitude = Math.max(...coordinates.map(coord => coord[1]));

            mapInstance.setBounds([[minLongitude, minLatitude], [maxLongitude, maxLatitude]], {
                checkZoomRange: true,
                zoomMargin: mapMode === 'global' ? 50 : 10,
            });
        }
    }, [filteredPoints, mapInstance]);

    const handlePlacemarkClick = (point, cityId) => {
        console.log('Клик по точке', point);
        if (mapMode === 'global') {
            // const cityName = cities.find(item => item.id === cityId)?.name;

            dispatch(removeOldPoints());
            dispatch(moveNewPointsToPoints());
            dispatch(clearAnimatedPoints());

            dispatch(setMapMode('city'));
            dispatch(setCurrentCity(cityId));

            // navigate(`/${cityName}`);

            window.ym(50456719,'reachGoal','click_to_live_placemark')
        }
    }

    const prolongLoader = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setLoading(false);
        }, 2000);
    };
    
    const handlePlacemarkLoad = () => {
        if (!loading) {
            setLoading(true);
        }

        prolongLoader();
    };
    
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const memoizedPoints = useMemo(() => filteredPoints, [filteredPoints]);
    const memoizedCities = useMemo(() => cities, [cities]);

    const memoizedPlacemarks = useMemo(() => {
        return memoizedPoints.map(point => (
            <MemoizedPlacemark key={point.id} point={point} isMobile={isMobile} onClick={() => handlePlacemarkClick(point, point.cityId)} onLoad={handlePlacemarkLoad}/>
        ));
    }, [memoizedPoints, mapMode, currentCityId]);

    const memoizedBalloons = useMemo(() => {
        if (mapMode === 'global') {
            return memoizedCities.map((point, index) => (
                index === activeBaloonIndex && (
                    <MemoizedBalloon
                        key={point.id}
                        point={point}
                        mapMode={mapMode}
                        onClick={() => handlePlacemarkClick(point, point.id)}
                    />
                )
            ));
        }
    }, [memoizedCities, mapMode, activeBaloonIndex]);

    const memoizedNewPlacemarks = useMemo(() => {
        return newPoints
        .filter(point => currentCityId === null || point.cityId === currentCityId)
        .map(point => (
            <Placemark
                key={point.id}
                geometry={point.coords}
                options={{
                    iconLayout: 'default#imageWithContent',
                    iconImageHref: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(``)}`,
                    iconImageSize: isMobile ? [16, 16] : [20, 20],
                    iconImageOffset: isMobile ? [-8, -8] : [-10, -10],
                    zIndex: 50,
                }}
                properties={{
                    iconContent: `
                        <div>
                            <svg width="${isMobile ? 16 : 20}" height="${isMobile ? 16 : 20}" fill="red" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="32" cy="28" r="12"/>
                            </svg>
                            <div class="${animatedPoints[point.id] ? 'ripple' : ''}"></div>
                        </div>
                    `,
                }}
                onClick={() => handlePlacemarkClick(point)}
            />
        ));
    }, [newPoints]);

    return (
        <>
            {loading &&
                <Flex
                    width='100%'
                    height='100%'
                    justify='center'
                    align='center'
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 300,
                        backdropFilter: 'blur(5px)',
                    }}
                >
                    <Spinner/>
                </Flex>
            }
            <YMaps
                query={{
                    apikey: apiKey,
                    lang: 'ru_RU',
                    load: 'package.full',
                }}
            >
                <Map
                    instanceRef={(ref) => {
                        if (ref) {
                            mapRef.current = ref;
                            mapInstance = ref;
                            mapInstance.behaviors.disable(['scrollZoom', 'dblClickZoom', 'multiTouch']);
                        }
                    }}
                    defaultState={{
                        center: globalMapCenter,
                        zoom: 8,
                        yandexMapDisablePoiInteractivity: true,
                        controls: [],
                    }}
                    options={{
                        scrollZoom: false,
                        dblClickZoom: false,
                        maxZoom: maxZoom,
                        suppressMapOpenBlock: true,
                        tileQuality: 'low',
                    }}
                    width='100%'
                    height='100%'
                >
                    {memoizedBalloons}
                    {memoizedPlacemarks}
                    {memoizedNewPlacemarks}
                </Map>
            </YMaps>
        </>
    )
}

export default MapContainer;

const MemoizedPlacemark = React.memo(({ point, isMobile, onClick, onLoad }) => (
    <Placemark
        key={point.id}
        geometry={point.coords}
        options={{
            iconLayout: 'default#imageWithContent',
            iconImageHref: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(``)}`,
            iconImageSize: isMobile ? [16, 16] : [20, 20],
            iconImageOffset: isMobile ? [-8, -8] : [-10, -10],
            zIndex: 100,
        }}
        properties={{
            iconContent: `
                <svg width="${isMobile ? 16 : 20}" height="${isMobile ? 16 : 20}" fill="red" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="32" cy="28" r="12"/>
                </svg>
            `,
        }}
        onClick={onClick}
        onLoad={onLoad}
    />
));

const MemoizedBalloon = React.memo(({ point, onClick }) => {
    return (
      <Placemark
        key={point.id}
        geometry={point.coords}
        options={{
          iconLayout: 'default#imageWithContent',
          iconImageHref: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(``)}`,
          iconImageSize: [120, 30],
          iconImageOffset: [-60, -60],
          zIndex: 150,
        }}
        properties={{
          iconContent: `
            <div class="city-label">
              <div>${point.name}</div>
            </div>
          `,
        }}
        onClick={onClick}
      />
    );
  });