import { combineReducers } from '@reduxjs/toolkit';
import commonReducer from './utils/commonReducer';
import mapReducer from './utils/mapReducer';

const combineReducer = combineReducers({
    common: commonReducer,
    map: mapReducer,
});

export default combineReducer;
