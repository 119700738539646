import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

export const GlobalStyle = createGlobalStyle`
	:root {
		--bg: #111111;
		--main-color: #EB273B;
		--text-color: #ffffff;

		--font-size: 16px;

		--radius-small: 8px;
		--radius-medium: 16px;
		--radius-large: 32px;
	}

	*,
	*::before,
	*::after {
		padding: 0;
		margin: 0;
		border: 0;
		box-sizing: border-box;
	}
	a {
		text-decoration: none;
		cursor: pointer;
	}
	ul,
	ol,
	li {
		list-style: none;
	}
	img {
		vertical-align: top;
	}
	button{
		background: transparent;
		cursor: pointer;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: inherit;
		font-size: inherit;
	}
	html,
	body {
		height: 100%;
		line-height: 1;
		font-family: Montserrat, sans-serif;
		font-size: var(--font-size);
		background: var(--bg);
	}

	.ymaps-2-1-79-ground-pane {
		filter: brightness(1) saturate(5) grayscale(1) invert(1);
	}

	.ymaps-2-1-79-copyrights-pane {
		display: none;
	}

	.ymaps-2-1-79-map-bg{
		background: var(--bg) !important;
	}

	@keyframes levitate {
		0% {
			transform: translateY(0);
		}
		50% {
			transform: translateY(-10px);
		}
		100% {
			transform: translateY(0);
		}
	}
	
	@keyframes fadeInOut {
		0% {
			opacity: 0;
		}
		10% {
			opacity: 1;
		}
		90% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes shakeWithPause {
		0%, 10% {
			transform: translateX(0);
		}
		15%, 25%, 35% {
			transform: translateX(-5px);
		}
		20%, 30%, 40% {
			transform: translateX(5px);
		}
		45%, 100% {
			transform: translateX(0);
		}
	}

	.city-label {
		position: relative;
		width: fit-content;
		min-width: 120px;
		padding: 10px;
		background: red;
		border-radius: 100px;
		font-size: 16px;
		font-weight: 600;
		color: var(--text-color);
		animation: fadeInOut 4s ease-in-out, levitate 4s ease-in-out;

		&::after {
			content: '';
			position: absolute;
			top: calc(100% - 5px);
			left: 60px;
			transform: translate(-50%, 0);
			width: 20px;
			height: 20px;
			background: url('data:image/svg+xml;utf8,<svg viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.4999 11C9.3297 5.34932 10.7228 2.63423 15 -1.49012e-08H-1.96945e-05C4.29391 2.65941 5.73407 5.31069 7.4999 11Z" fill="red"/></svg>') no-repeat center/contain;
		}
	}


	@keyframes ripple {
		0% {
			transform: scale(1);
			opacity: 1;
		}
		100% {
			transform: scale(20);
			opacity: 0;
		}
	}

	.ripple {
		position: absolute;
		top: 0;
		left: 0;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: rgba(0, 150, 255, 0.5);
		animation: ripple 2s forwards;
	}
`;

export const Flex = styled.div`
	display: flex;
	flex-direction: ${(props) => props.column && 'column'};
	justify-content: ${(props) => props.justify && props.justify};
	align-items: ${(props) => props.align && props.align};
	gap: ${(props) => props.gap && props.gap};
	flex-wrap: ${(props) => props.wrap && props.wrap};
	width: ${(props) => props.width && props.width};
	height: ${(props) => props.height && props.height};
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	padding: 30px;
	width: 100%;
	@media (max-width: 500px) {
		padding: 15px;
	}
`;
