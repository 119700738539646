import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setIsSoundOn } from '../../redux/reducers/utils/commonReducer';
import { clearAnimatedPoints, moveNewPointsToPoints, removeOldPoints, setCurrentCity, setMapMode } from '../../redux/reducers/utils/mapReducer';
import styled from "styled-components";
import { Flex } from "../../styles";
import { AppleIcon, AppstoreIcon, BackArrowIcon, CommunicationIcon, SoundOffIcon, SoundOnIcon } from '../../components/Icons';
import Button from "../../components/Button";

const Footer = () => {
    // const navigate = useNavigate();
    const { device, isSoundOn } = useSelector((store) => store.common);
    const { mapMode } = useSelector((store) => store.map);
    const dispatch = useDispatch();
    const [showTooltip, setShowTooltip] = useState(true);

    const handleSoundSwitch = () => {
        setShowTooltip(false);
        dispatch(setIsSoundOn(!isSoundOn));
    }

    const handleBackToGlobal = () => {
        dispatch(setMapMode('global'));
        dispatch(setCurrentCity(null));
        dispatch(removeOldPoints());
        dispatch(moveNewPointsToPoints());
        dispatch(clearAnimatedPoints());

        // navigate('/');
    }

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Сытый',
                    text: 'Сытый - пульс проекта. Заказы на карте в реальном времени.',
                    url: window.location.href,
                });
                console.log('Контент успешно отправлен');
            } catch (error) {
                console.error('Ошибка при отправке контента', error);
            }
        } else {
            alert('Ваш браузер не поддерживает Web Share API');
        }

        window.ym(50456719,'reachGoal','click_to_communicate_button');
    };

    return (
        <Wrapper isMobile={device === 'mobile'}>
            <Flex flex='1' justify='space-between' gap='10px' wrap='wrap'>
                {mapMode === 'city' &&
                    <Button danger onClick={handleBackToGlobal}>
                        <BackArrowIcon/>
                    </Button>
                }
                <Right justify='space-between' gap='10px'>
                    <a href='https://apps.apple.com/us/app/%D1%81%D1%8B%D1%82%D1%8B%D0%B9/id1361986950?l=ru&ls=1' target='_blank' rel="noopener nofollow">
                        <Button onClick={() => window.ym(50456719,'reachGoal','click_to_live_google_play')}>
                            <AppleIcon/>
                        </Button>
                    </a>
                    <a href='https://play.google.com/store/apps/details?id=air.com.menu4me.dinner.sitiyoffice&pli=1' target='_blank' rel="noopener nofollow">
                        <Button onClick={() => window.ym(50456719,'reachGoal','click_to_live_app_store')}>
                            <AppstoreIcon/>
                        </Button>
                    </a>
                    <Button onClick={handleShare}>
                        Ого! Покажу другу <CommunicationIcon/>
                    </Button>
                    <Top justify='flex-end' gap='12px'>
                        <Button onClick={handleSoundSwitch}>
                            {isSoundOn ? <SoundOnIcon/> : <SoundOffIcon/>}
                        </Button>
                        <Tooltip visible={showTooltip} onClick={() => setShowTooltip(false)}>Включите звук, чтобы услышать как приходят новые заказы</Tooltip>
                    </Top>
                </Right>
            </Flex>
        </Wrapper>
    )
}

export default Footer;

const Wrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;
    width: 100%;
    padding: ${(props) => props.isMobile ? '25px 15px' : '30px'};
    user-select: none;
    pointer-events: none;
`
const Right = styled(Flex)`
    position: relative;
    margin-left: auto;
`
const Top = styled(Flex)`
    position: absolute;
    bottom: calc(100% + 10px);
    right: 0;
    margin-left: auto;
`
const Tooltip = styled.div`
    position: absolute;
    bottom: calc(100% + 5px);
    right: 100%;
    z-index: 2;
    width: 260px;
    background: lightgrey;
    color: #2A2A2A;
    padding: 10px 12px;
    border-radius: 18px;
    font-size: 14px;
    opacity: ${(props) => props.visible ? 1 : 0};
    visibility: ${(props) => props.visible ? 'visible' : 'hidden'};
    transition: all .5s ease-out;
    pointer-events: all;
    &::after {
        content: '';
        position: absolute;
        top: calc(100% - 15px);
        right: -20px;
        transform: translate(-50%, 0);
        width: 20px;
        height: 20px;
        background: url('data:image/svg+xml;utf8,<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.486 20.4671C12.0989 19.5581 7.56029 17.689 0.0188955 9.75228L9.77118 -3.471e-07C11.0188 9.39866 13.4011 13.8626 20.486 20.4671Z" fill="lightgrey"/></svg>') no-repeat center/contain;
    }
`