import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { LogoIcon } from '../../components/Icons';
import StatsCarousel from "../../components/StatsCarousel";
import { Flex } from "../../styles";
import TimeComponent from "../../components/TimeComponent";

const Header = () => {
    const { device } = useSelector((store) => store.common);

    return (
        <Wrapper isMobile={device === 'mobile'}>
            <StatsCarousel/>
            <LogoContainer column gap='2px' onClick={() => window.ym(50456719,'reachGoal','click_to_live_logo')}>
                <a href='https://sitiy.ru/app' target='_blank' rel="noopener nofollow">
                    <StyledLogo/>
                </a>
                <TimeComponent/>
            </LogoContainer>
        </Wrapper>
    )
}

export default Header;

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: ${(props) => props.isMobile ? '25px 15px' : '30px'};
    user-select: none;
    pointer-events: none;
`
const LogoContainer = styled(Flex)`
    position: absolute;
    right: 30px;
    z-index: 2;
    @media (max-width: 500px) {
        right: 15px;
    }
`
const StyledLogo = styled(LogoIcon)`
    width: 100px;
    pointer-events: all;
    color: var(--main-color);
    stroke: var(--main-color);
    transition: all .3s;
    &:hover {
        scale: 1.05;
    }

`