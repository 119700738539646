import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Blackout = () => {
    const { device, orientation } = useSelector((store) => store.common);

    return (
        <Wrapper device={device} orientation={orientation}>
            {device === 'mobile' && orientation === 'landscape' ?
                <>
                    <BlackoutLeft></BlackoutLeft>
                    <BlackoutRight></BlackoutRight>
                </>
            :
                <>
                    <BlackoutTop isMobile={device === 'mobile'}></BlackoutTop>
                    <BlackoutBottom isMobile={device === 'mobile'}></BlackoutBottom>
                </>
            }
        </Wrapper>
    );
};

export default Blackout;

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: ${(props) => props.device === 'mobile' && props.orientation === 'landscape' ? 'row' : 'column'};
    justify-content: space-between;
    width: 100%;
    height: 100%;
    z-index: 1;
    user-select: none;
    pointer-events: none;
`
const BlackoutTop = styled.div`
    width: 100%;
    height: ${(props) => props.isMobile ? '150px' : '100px'};
    background: linear-gradient(180deg, #0e0e0e 38.04%, rgba(28, 28, 28, 0) 104.9%);
`
const BlackoutBottom = styled.div`
    width: 100%;
    height: ${(props) => props.isMobile ? '150px' : '100px'};
    background: linear-gradient(0, #0e0e0e 38.04%, rgba(28, 28, 28, 0) 104.9%);
`
const BlackoutLeft = styled.div`
    width: 100px;
    height: 100%;
    background: linear-gradient(90deg, #0e0e0e 38.04%, rgba(28, 28, 28, 0) 104.9%);
`
const BlackoutRight = styled.div`
    width: 100px;
    height: 100%;
    background: linear-gradient(270deg, #0e0e0e 38.04%, rgba(28, 28, 28, 0) 104.9%);
`
