import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './redux/configureStore';
import { GlobalStyle } from './styles';
import App from './App';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
    <Provider store={store}>
        <GlobalStyle/>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);
