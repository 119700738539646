import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { Flex } from '../styles';
import {
    PackageIcon,
    CourierIcon,
    CityIcon,
    KitchenIcon,
    SpeedIcon,
} from '../components/Icons';

const StatsCarousel = () => {
    const { stats } = useSelector((store) => store.map);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        if (stats.length > 0) {
            const interval = setInterval(() => {

                setIsVisible(false);

                setTimeout(() => {
                    setCurrentIndex((prevIndex) => (prevIndex + 1) % stats.length);
                    setIsVisible(true);
                }, 1000); // время должно соответствовать длительности анимации `slideOut`
            }, 6000);

            return () => clearInterval(interval);
        }
    }, [stats]);

    const currentStat = stats[currentIndex];

    const renderIcons = () => {
        switch (currentStat?.title) {
            case 'Заказов сделано':
                return <PackageIcon />;
            case 'Курьеров в доставке':
                return <CourierIcon />;
            case 'Сытых городов':
                return <CityIcon />;
            case 'Кухонь-партнёров':
                return <KitchenIcon />;
            case 'Проехали километров':
                return <SpeedIcon />;
            default:
                return <PackageIcon />;
        }
    };

    return (
        <Wrapper>
            <Card isVisible={isVisible}>
                <IconContainer>
                    {renderIcons()}
                </IconContainer>
                <Flex gap='10px' wrap='wrap'>
                    <Count>{currentStat?.count}</Count>
                    <Flex column>
                        <Title>{currentStat?.title}</Title>
                        <Text>{currentStat?.text}</Text>
                    </Flex>
                </Flex>
            </Card>
        </Wrapper>
    );
};

export default StatsCarousel;

const slideIn = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
`;

const Wrapper = styled.div`
    z-index: 2;
    user-select: none;
    pointer-events: none;
`;

const Card = styled.div`
    display: flex;
    gap: 12px;
    width: calc(100vw - 150px);
    animation: ${({ isVisible }) => (isVisible ? slideIn : slideOut)} 1s ease forwards;
    transition: opacity 1s ease;
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    height: 40px;
    background: #2C2C2C;
    border-radius: 40px;
`;

const Count = styled.div`
    font-size: 40px;
    font-weight: 700;
    color: var(--text-color);
    text-wrap: nowrap;
    @media (max-width: 500px) {
        font-size: 40px;
    }
`;

const Title = styled.div`
    font-size: var(--font-size);
    color: var(--text-color);
    text-wrap: nowrap;
    @media (max-width: 500px) {
        font-size: var(--font-size);
    }
`;

const Text = styled.div`
    font-size: var(--font-size);
    color: #FFFFFF80;
    text-wrap: nowrap;
    @media (max-width: 500px) {
        font-size: var(--font-size);
    }
`;
